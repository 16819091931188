import React from "react";

function Custom404Image() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 1262.555 713.231"
    >
      <g data-name="Group 2603" transform="translate(-186.945 -1095)">
        <g data-name="Group 2602" transform="translate(945 154.5)">
          <path
            fill="#f2f2f2"
            d="M480.2 332.742c0 119.578-71.087 161.33-158.777 161.33s-158.777-41.752-158.777-161.33 158.777-271.7 158.777-271.7S480.2 213.164 480.2 332.742z"
            data-name="Path 3997"
            transform="translate(24.296 1033.958)"
          ></path>
          <path
            fill="#3f3d56"
            d="M299.381 414.738l1.626-100.076 67.674-123.808-67.419 108.109.731-45 46.641-89.574-46.448 77.666 1.314-80.931 49.945-71.312-49.737 58.588.821-148.4-5.163 196.454.424-8.1-50.779-77.725 49.965 93.283-4.731 90.386-.141-2.4-58.539-81.798 58.362 90.27-.591 11.3-.106.17.048.928-12 229.318h16.038l1.924-118.447 58.218-90.049z"
            data-name="Path 3998"
            transform="translate(40.558 1095)"
          ></path>
        </g>
        <g data-name="Group 2601">
          <path
            fill="#f2f2f2"
            d="M563.68 404.164c0 151.011-89.774 203.739-200.516 203.739s-200.515-52.728-200.515-203.739S363.165 61.042 363.165 61.042 563.68 253.152 563.68 404.164z"
            data-name="Path 3997"
            transform="translate(24.296 1033.958)"
          ></path>
          <path
            fill="#3f3d56"
            d="M316.156 523.761l2.054-126.383 85.464-156.354-85.142 136.528.923-56.827 58.9-113.12-58.655 98.082 1.66-102.206 63.074-90.058-62.812 73.986L322.658 0l-6.52 248.1.536-10.235-64.127-98.165 63.1 117.8-5.975 114.146-.178-3.029-73.928-103.3 73.7 114-.747 14.275-.134.215.061 1.172-15.159 289.6h20.254l2.43-149.584 73.529-113.71z"
            data-name="Path 3998"
            transform="translate(64 1095)"
          ></path>
        </g>
        <ellipse
          cx="527.5"
          cy="34.922"
          fill="#f2f2f2"
          data-name="Ellipse 1333"
          rx="527.5"
          ry="34.922"
          transform="translate(311.143 1738.388)"
        ></ellipse>
        <path
          fill="#2f2e41"
          d="M583.48 324.894c-85.944 0-147.651 55.139-147.651 183.8 0 145.813 61.707 184.411 147.651 184.411s151.327-42.273 151.327-184.411c0-151.941-65.383-183.8-151.327-183.8zm.565 319.808c-59.527 0-90.626-34.923-90.626-135.916 0-89.112 32.372-136.1 91.9-136.1s91.9 30.868 91.9 136.1c-.002 98.448-33.648 135.914-93.174 135.914z"
          data-name="Path 3958"
          transform="translate(234.945 1066.115)"
        ></path>
        <path
          fill="#87b738"
          d="M384.365 591.4h-35.534V486.762a20.956 20.956 0 00-20.956-20.956h-8.326a20.956 20.956 0 00-20.956 20.956V591.4h-100.23a11.963 11.963 0 01-10.578-17.552l106.082-200.78a20.956 20.956 0 00-9.58-28.739l-6.262-2.957a20.956 20.956 0 00-27.429 9.07L121.214 592.475a28.416 28.416 0 00-3.356 13.4 28.416 28.416 0 0028.416 28.416h152.319v66.167a25.119 25.119 0 0025.119 25.119 25.119 25.119 0 0025.119-25.119v-66.171h35.534a21.443 21.443 0 0021.443-21.443 21.443 21.443 0 00-21.443-21.444z"
          data-name="Path 3959"
          transform="translate(234.945 1066.115)"
        ></path>
        <path
          fill="#87b738"
          d="M1042.362 591.4h-35.534V486.762a20.956 20.956 0 00-20.956-20.956h-8.326a20.956 20.956 0 00-20.956 20.956V591.4H856.359a11.963 11.963 0 01-10.578-17.552l106.082-200.78a20.956 20.956 0 00-9.58-28.739l-6.262-2.957a20.956 20.956 0 00-27.429 9.07L779.211 592.475a28.416 28.416 0 00-3.356 13.4 28.416 28.416 0 0028.416 28.416h152.318v66.167a25.119 25.119 0 0025.119 25.119 25.119 25.119 0 0025.119-25.119v-66.171h35.534a21.443 21.443 0 0021.443-21.443 21.443 21.443 0 00-21.443-21.443z"
          data-name="Path 3960"
          transform="translate(234.945 1066.115)"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M629.113 1645.263h-35.534v-104.639a20.956 20.956 0 00-20.956-20.956h-8.326a20.956 20.956 0 00-20.952 20.956v104.639H443.11a11.963 11.963 0 01-10.577-17.548l106.082-200.784a20.956 20.956 0 00-9.58-28.739l-6.262-2.957a20.956 20.956 0 00-27.429 9.07l-129.382 242.032a28.416 28.416 0 00-3.356 13.4h0a28.416 28.416 0 0028.416 28.416h152.323v66.162a25.119 25.119 0 0025.119 25.119h0a25.119 25.119 0 0025.114-25.119v-66.166h35.534a21.443 21.443 0 0021.443-21.443h0a21.443 21.443 0 00-21.443-21.443z"
          data-name="Path 3961"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M1295.687 1645.263h-35.534v-104.639a20.956 20.956 0 00-20.956-20.956h-8.326a20.956 20.956 0 00-20.956 20.956v104.639h-100.231a11.963 11.963 0 01-10.578-17.548l106.083-200.78a20.956 20.956 0 00-9.58-28.739l-6.264-2.961a20.956 20.956 0 00-27.429 9.07l-129.381 242.032a28.415 28.415 0 00-3.356 13.4h0a28.416 28.416 0 0028.416 28.416h152.319v66.162a25.119 25.119 0 0025.119 25.119h0a25.119 25.119 0 0025.119-25.119v-66.166h35.534a21.443 21.443 0 0021.443-21.443h0a21.443 21.443 0 00-21.442-21.443z"
          data-name="Path 3962"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M838.03 1379.981c-85.944 0-147.651 55.139-147.651 183.8 0 145.813 61.707 184.411 147.651 184.411s151.327-42.277 151.327-184.413c0-151.939-65.383-183.798-151.327-183.798zm.565 319.808c-59.527 0-90.626-34.923-90.626-135.916 0-89.112 32.372-136.1 91.9-136.1s91.9 30.868 91.9 136.1c0 98.447-33.645 135.916-93.172 135.916z"
          data-name="Path 3963"
        ></path>
        <path
          fill="#57b894"
          d="M1069.918 577.434a20.813 20.813 0 102.772-39.915l.521 10.712-5.068-9.18a20.734 20.734 0 00-10.684 11.723 20.406 20.406 0 00-1.2 5.63 20.809 20.809 0 0013.659 21.03z"
          data-name="Path 3979"
          transform="translate(234.945 1066.115)"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M1329.945 1767.793c-1.789-9.11 5.963-17.187 13.621-22.437s16.6-10.408 19.218-19.317c3.755-12.8-7.43-24.53-16.136-34.642a125.292 125.292 0 01-16.524-24.557c-1.811-3.533-3.476-7.225-3.952-11.166-.686-5.675 1.137-11.323 2.974-16.737q9.179-27.052 19.628-53.65"
          data-name="Path 3980"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M1305.72 1640.791a20.813 20.813 0 102.772-39.915l.521 10.712-5.068-9.18a20.734 20.734 0 00-10.684 11.723 20.411 20.411 0 00-1.2 5.63 20.808 20.808 0 0013.659 21.03z"
          data-name="Path 3981"
        ></path>
        <path
          fill="#57b894"
          d="M1092.451 515.473a20.788 20.788 0 0114.98-13.2l1.714 10.184 3.177-10.7a20.81 20.81 0 11-19.871 13.71z"
          data-name="Path 3982"
          transform="translate(234.945 1066.115)"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M1328.539 1577.915a20.788 20.788 0 0114.98-13.2l1.714 10.184 3.177-10.7a20.81 20.81 0 11-19.871 13.71z"
          data-name="Path 3983"
        ></path>
        <path
          fill="#57b894"
          d="M1108.045 625.489a20.81 20.81 0 0018.419-37.023l-2.441 8.219-1.731-10.3a.362.362 0 00-.053-.02 20.811 20.811 0 10-14.194 39.127z"
          data-name="Path 3984"
          transform="translate(234.945 1066.115)"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M1343.98 1687.879a20.81 20.81 0 0018.419-37.023l-2.441 8.219-1.731-10.3a.359.359 0 00-.053-.02 20.811 20.811 0 00-14.194 39.127z"
          data-name="Path 3985"
        ></path>
        <path
          fill="#57b894"
          d="M1086.378 660.051a20.8 20.8 0 104.011-16.3l9.273 13.957-12.67-7.408a20.617 20.617 0 00-.613 9.749z"
          data-name="Path 3986"
          transform="translate(234.945 1066.115)"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M1322.18 1723.409a20.8 20.8 0 104.011-16.3l9.273 13.957-12.67-7.408a20.617 20.617 0 00-.613 9.749z"
          data-name="Path 3987"
        ></path>
        <path
          fill="#57b894"
          d="M72.061 628.133a13.674 13.674 0 101.821-26.225l.342 7.038-3.33-6.032a13.623 13.623 0 00-7.019 7.7 13.41 13.41 0 00-.787 3.7 13.672 13.672 0 008.972 13.818z"
          data-name="Path 3988"
          transform="translate(234.945 1066.115)"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M323.483 1775.878c-1.175-5.986 3.918-11.292 8.949-14.741s10.91-6.838 12.626-12.692c2.467-8.412-4.882-16.117-10.6-22.76a82.324 82.324 0 01-10.856-16.135 22.21 22.21 0 01-2.6-7.336c-.451-3.729.747-7.439 1.954-11q6.031-17.773 12.9-35.249"
          data-name="Path 3989"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M307.57 1692.436a13.674 13.674 0 101.821-26.221l.342 7.038-3.33-6.032a13.623 13.623 0 00-7.019 7.7 13.41 13.41 0 00-.787 3.7 13.672 13.672 0 008.973 13.818z"
          data-name="Path 3990"
        ></path>
        <path
          fill="#57b894"
          d="M86.866 587.423a13.658 13.658 0 019.842-8.671l1.126 6.691 2.087-7.027a13.673 13.673 0 11-13.055 9.007z"
          data-name="Path 3991"
          transform="translate(234.945 1066.115)"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M322.562 1651.122a13.658 13.658 0 019.842-8.671l1.126 6.691 2.088-7.027a13.673 13.673 0 11-13.055 9.007z"
          data-name="Path 3992"
        ></path>
        <path
          fill="#57b894"
          d="M97.112 659.706a13.673 13.673 0 0012.1-24.325l-1.6 5.4-1.137-6.77a.229.229 0 00-.035-.013 13.673 13.673 0 00-9.326 25.707z"
          data-name="Path 3993"
          transform="translate(234.945 1066.115)"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M332.707 1723.374a13.673 13.673 0 0012.1-24.325l-1.6 5.4-1.137-6.77a.243.243 0 00-.035-.013 13.673 13.673 0 00-9.326 25.707z"
          data-name="Path 3994"
        ></path>
        <path
          fill="#57b894"
          d="M82.876 682.414a13.667 13.667 0 102.635-10.708l6.092 9.17-8.324-4.867a13.545 13.545 0 00-.4 6.4z"
          data-name="Path 3995"
          transform="translate(234.945 1066.115)"
        ></path>
        <path
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
          strokeWidth="1"
          d="M318.384 1746.715a13.667 13.667 0 102.635-10.708l6.092 9.17-8.324-4.862a13.545 13.545 0 00-.4 6.4z"
          data-name="Path 3996"
        ></path>
      </g>
    </svg>
  );
}

export default Custom404Image;
